<template>
  <div class="text-detail__body" ref="textDetailBody" v-if="content" v-html="content">
    
  </div>
</template>

<script>
import Vue from "vue";
import InfoDrop from "@/components/pageComponents/InfoDrop.vue";
import ClinicalCaseCarousel from "@/components/pageComponents/ClinicalCaseCarousel.vue";
import ClinicalCaseTabs from "@/components/pageComponents/ClinicalCaseTabs.vue";
export default {
  name: "TextDetailBody",
  props: {
    content: String,
  },
  mounted() {
    const vm = this
    setTimeout(() => {
      if (
        this.$refs.textDetailBody &&
        this.$refs.textDetailBody.querySelector(".dropdown")
      ) {
        document.querySelectorAll(".dropdown").forEach((dropdown) => {
          let ComponentClass = Vue.extend(InfoDrop);
          let instance = new ComponentClass({
            parent: this,
            $route: this.$route,
            propsData: {
              item: {
                title: dropdown.querySelector(".dropdown__title").innerHTML,
                content: dropdown.querySelector(".dropdown__content").innerHTML,
              },
            },
          });
          instance.$on("openImage", (e) => vm.$emit("openImage", e));
          instance.$mount(); // pass nothing
          dropdown.innerHTML = "";
          dropdown.appendChild(instance.$el);
        });
      }
      if (
        this.$refs.textDetailBody &&
        this.$refs.textDetailBody.querySelector(
          ".clinical-case-carousel__container"
        )
      ) {
        document
          .querySelectorAll(".clinical-case-carousel__container")
          .forEach((slider) => {
            let ComponentClass = Vue.extend(ClinicalCaseCarousel);
            let instance = new ComponentClass({
              propsData: {
                Slides: Array.from(slider.children),
              },
            });
            instance.$on("openImage", (e) => vm.$emit("openImage", e));
            instance.$mount(); // pass nothing
            slider.innerHTML = "";
            slider.appendChild(instance.$el);
          });
      }
       if (
        this.$refs.textDetailBody &&
        this.$refs.textDetailBody.querySelector(
          ".clinical-case__tabs-container"
        )
      ) {
        document
          .querySelectorAll(".clinical-case__tabs-container")
          .forEach((tabsContainer) => {
            let ComponentClass = Vue.extend(ClinicalCaseTabs);
            let tabsItems = tabsContainer.querySelector(".clinical-case__tabs");
            let tabsContent = tabsContainer.querySelector(".clinical-case__tabs-content");
            let instance = new ComponentClass({
              propsData: {
                tabs: Array.from(tabsItems.children),
                tabsContent: Array.from(tabsContent.children),
              },
            });
            instance.$mount(); // pass nothing
            tabsContainer.innerHTML = "";
            tabsContainer.appendChild(instance.$el);
          });
      }
    }, 500);
  },
};
</script>
<style>
.text-detail__body a:not(.material) {
  line-break: anywhere;
}
</style>
